.actions_upper {
  margin-bottom: 1rem;
}

.button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: none;
  height: 2.5rem;
  width: 10rem;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 0.5rem;
}

.button:hover {
  color: bisque;
}

.actions_lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
