.control_group {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: blue;
  margin-bottom: 10px;
  border-radius: 25px;
  width: 300px;
  height: 150px;
  text-align: center;
  background: white;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  position: relative;
}

.card_reverse {
  animation: 1s ease-in-out forwards fadeIn;
  -webkit-animation: 1s ease-in-out forwards fadeIn;
  -moz-animation: 1s ease-in-out forwards fadeIn;
  -o-animation: 1s ease-in-out forwards fadeIn;
  -ms-animation: 1s ease-in-out forwards fadeIn;
  font-size: 2rem;
}

.card_speaker {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
  opacity: 0.6;
}

.vert_menu {
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;
  opacity: 0.6;
}

.card_speaker:hover {
  opacity: 1;
}

.vert_menu:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: white;
  }
  100% {
    opacity: 1;
    background-color: aliceblue;
    color: #33059e;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: white;
  }
  100% {
    opacity: 1;
    background-color: aliceblue;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: white;
  }
  100% {
    opacity: 1;
    background-color: aliceblue;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: white;
  }
  100% {
    opacity: 1;
    background-color: aliceblue;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: white;
  }
  100% {
    opacity: 1;
    background-color: aliceblue;
  }
}

.example {
  text-align: left;
  font-weight: 100;
  padding: 0.5rem;
}

.form_control {
  margin-bottom: 1rem;
}

.form_control input,
.form_control label {
  display: block;
}

.form_control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form_control input,
.form_control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form_control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control_group .form_control {
  min-width: 15rem;
  flex: 1;
}

.button {
  background-color: transparent;
  color: blue;
  border: none;
  cursor: pointer;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form_actions {
  text-align: right;
  margin-bottom: 20px;
}

.form_actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error_text {
  color: #b40e0e;
  margin: 0;
  padding: 0;
  font-size: small;
  max-width: 20rem;
}
