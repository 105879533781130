@keyframes example {
  0% {
    color: blue;
  }
  50% {
    color: red;
  }
  100% {
    color: blue;
  }
}

.header {
  margin: auto;
  max-width: 20rem;
  padding-bottom: 10px;
  color: blue;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: 3;
}

.form_control {
  margin-bottom: 1rem;
}

.form_control input,
.form_control label {
  display: block;
}

.form_control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form_control input,
.form_control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form_control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control_group {
  display: flex;
  flex-direction: column;
  align-content: center;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control_group .form_control {
  min-width: 15rem;
  flex: 1;
}

.button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 1rem;
}

.button:hover,
.button:active {
  background-color: #33059e;
  border-color: #33059e;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form_actions {
  text-align: center;
}

.form_actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error_text {
  color: #b40e0e;
  margin: 0;
  padding: 0;
  font-size: small;
  max-width: 20rem;
}
