.app {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 43rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;
  margin: 3rem auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions {
  display: flex;
  margin: 1rem;
}

.button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  height: 3rem;
  width: 5rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 1rem;
}
