.header {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  background-color: #240370;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.header_title {
  margin-left: 1rem;
}

.active {
  visibility: hidden;
}

.navlink {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

.navlink:hover {
  color: bisque;
}

.button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  cursor: pointer;
  margin-left: 1rem;
}

.button:hover {
  color: bisque;
}

@media (max-width: 600px) {
  .header_title {
    display: none;
  }
}
